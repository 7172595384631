



































import Vue from "vue";
import Footer from "@/components/back-footer.vue";
export default Vue.extend({
data:function(){
return{
sentences:[
    {
        value:"Growth",
        sentence:"Accept growth"
    },
    {
        value:"Optimism",
        sentence:"Embrace optimism"
    },
  {
      value:"Love",
      sentence:"Spread love"
  },
  {
      value:"Mindfulness",
      sentence:"Be more mindful"
  },
  {
      value:"Balance",
      sentence:"Acknowledge balance"
  }
]
}
},
  components: {
    Footer,
  },

  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },

});
